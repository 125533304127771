// MEDIA QUERY BREAK POINTS
// calculation -  device-width/16px = break point in em
// $bp-laptop-L: 90em; // 1440px
// $bp-laptop-M: 64em; // 1024px
// $bp-tab-L: 45em; // 720px
// $bp-tab: 48em; // 800px
// $bp-mobile: 37.5em; // 600px
// $bp-mobile-xs:  20em; // 320px

// // ipad
// $bp-ipad-mini: 768px;
// $bp-ipad-air: 820px;
// $bp-ipad-pro: 1024px;
// $iphone-12-pro: 390px;
// $iphone-pro-max:430px;
// $iphone-xr: 414px;
// $iphone-se:375px;

// // landscape
// $iphone-se-landscape: 667px;
// $iphone-xr-landscape: 896px;
// $iphone-12pro-landscape:844px;
// $iphone-14pro-landscape:932px;



// Break points provided by UX team
$bp-xl-device: 90em; //1440px
$bp-lg-device: 64em; //1024px
$bp-md-device: 45em; //720px
$sm-xs-device: 20em; //320px

// FONT FAMILY
$font-family: 'Roboto', sans-serif;

// CSS GLOBAL RESET
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
}

body {
  font-feature-settings: 'liga';
  font-family: $font-family;
}